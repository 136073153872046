<template>
  <div class="p-aliyunVideoPlayer">
    <div class="video-wrap">
      <!-- <span>视频组件</span> -->
      <div :id="videoPlayerId" />
    </div>
  </div>
</template>
<script>
import { renderVideo } from './usePlay'
export default {
  name: 'AliyunVideoPlayer',
  components: {},
  props: {
    // 视频id     //检测id有值会自动解析
    vid: {
      type: String,
      required: true
    },
    // 是否自动播放
    autoplay: {
      type: Boolean,
      default: false
    },
    // 自定义视频封面  仅当autoplay为false才生效
    cover: {
      type: String,
      default: null
    },
    // 视频展示高度  格式：**px
    vHeight: {
      type: String,
      default: '300px'
    }
  },
  data() {
    return {
      videoPlayer: null,
      videoPlayerId: `videoContent${Date.now()}`,
    }
  },
  watch: {
    vid: {
      handler() {
        this.show()
      },
      immediate: true
    }
  },
  methods: {
    async show() {
      this.videoPlayer = await renderVideo(this.vid, this.autoplay, this.vHeight, this.cover, this.videoPlayerId)
    },

    // 销毁实例
    close() {
      this.videoPlayer?.dispose()
      this.videoPlayer = null
    },

    pause() {
      this.videoPlayer?.pause()
    }
  }
}
</script>
<style scoped>
.video-wrap :deep(.prism-big-play-btn) {
  left: 50% !important;
  transform: translate(-50%, 50%) !important;
}
</style>
